import React from "react"
import { graphql, Link as RouterLink } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Link from "../../components/Link"
import { Box } from "../../components/Box"
import { Heading1,TextSmall } from "../../components/Typography"

import Img from "gatsby-image"
const ListHeading1 = styled(Heading1)`
  margin:0;
`
const PostBody = styled(Box)`
  h2{
    margin:3rem 0 1rem;
  }
  p{
    font-weight: 400;
    line-height: 2;
    color: var(--textNormal);
    margin-top: ${props => props.theme.spacing[3]};
    margin-bottom: ${props => props.theme.spacing[6]};
    font-size: ${props => props.theme.fontSize["base"]};
  }
  ul{
    list-style:decimal;
    margin-left:30px;
    line-height:2;
  }
  
  a[href^="https://carbon.now.sh"]{
    width:100%;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
 }
    &:after{
      display:none;
    }
  }
  a.gatsby-resp-image-link{
    
    &:after{
      display:none;
    }
    &:hover{
      border:gold 5px solid;
      margin:-5px;
    }
  }
  :not(pre) > code[class*="language-"], pre[class*="language-"]{
    background: #282C34;
  }
  code[class*="language-"], pre[class*="language-"]{
    font-style: normal;
    background: #282C34;
    padding: .5em;
    border-radius: 0.2em;
    color:#abb2bf;
    font-size:16px;
    font-family: 'Source Code Pro',Courier, 'Courier New', monospace !important;
    font-weight: 400;
    a{
      color:#abb2bf; 
    }
    a:after{
      display:none;
    }
  }
  em{
    position: relative;
    &:after {
      color:gold;
      position: absolute;
      top: 0.65em;
      left: 0;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      content: "・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・";
    }
  }
  @media (max-width: 414px) {
    em{
      font-weight:bold;
      &:after {
        display:none;
      }
    }
  }
`

export default ({ data }) => {
  const post = data.mdx
  const meta = [
    {
      name: `og:url`,
      content: data.site.siteMetadata.siteUrl + post.fields.slug,
    },
    {
      name: `og:type`,
      content: `article`,
    },
    {
      name: `og:locale`,
      content: post.frontmatter.locale,
    },
    {
      name: `article:author`,
      content: `https://www.avenirzheng.net`,
    },
  ]
  const imageMeta = post.frontmatter.image
    ? [
        {
          name: `twitter:image`,
          content:
            data.site.siteMetadata.siteUrl + post.frontmatter.image.publicURL,
        },
        {
          name: `og:image`,
          content:
            data.site.siteMetadata.siteUrl + post.frontmatter.image.publicURL,
        },
        {
          name: `og:image:secure_url`,
          content:
            data.site.siteMetadata.siteUrl + post.frontmatter.image.publicURL,
        },
      ]
    : []

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt}
        meta={[...meta, ...imageMeta]}
      />
      <Box maxWidth="640px" marginTop={32}>
        
        <ListHeading1>{post.frontmatter.title}</ListHeading1>
        <TextSmall>{post.frontmatter.date}</TextSmall>
        <Box marginBottom={4} marginTop={4}>
          {post.frontmatter.image && (
            <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
          )}
        </Box>
        <PostBody>
        <MDXRenderer>{post.body}</MDXRenderer>
        </PostBody>
        <Box element="footer" marginTop={32} marginBottom={32}>
          <Link element={RouterLink} color="white" underlined to="/writings">
            返回列表
          </Link>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
